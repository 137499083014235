<template>
  <div class="allProductsBox">
    <ul class="lists" v-if="calssLists">
      <li
        class="item"
        v-for="(item, index) in calssLists"
        :key="index"
        @click="getProductDetails(item)"
      >
        <van-image class="productImg" :src="item.ClassImg"></van-image>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue from "vue";
import { Image } from "vant";

Vue.use(Image);
export default {
  name: "AllProducts",
  components: {},
  data() {
    return {
      list: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12],
      calssLists: [],
    };
  },
  mounted() {
    this.loadProductClass();
  },
  methods: {
    // 获取搜索页数据
    loadProductClass() {
      this.$commonMethod.showLoading();
      let param = {};
      this.$api.index
        .loadProductClass(param)
        .then((res) => {
          this.calssLists = res.data;
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 跳转详情页
    getProductDetails(item) {
      this.$commonEnv.commonAction(this.$commonEnv.actionType.routerPush, {
        isParams: true,
        path: "search",
        name: "search",
        query: [
          {
            name: "ClassName",
            value: item.ClassName,
          },
          {
            name: "ClassId",
            value: item.ClassId,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.allProductsBox {
  position: relative;
  background-color: #fefefe;
  min-height: 100vh;
  .lists {
    // justify-content: space-around;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-top: 24.5px;
    @include publicFlex;
    .item {
      width: 25%;
      min-height: 99px;
      text-align: center;
      margin-bottom: 20px;

      .productImg {
        width: 16vw;
        height: 24vw;
        object-fit: cover;
      }
      .itemTitle {
        text-align: center;
        width: 55px;
        font-size: 10px;
        font-family: Helvetica;
        font-weight: 400;
        color: #2a2d33;
        word-wrap: break-word;
        white-space: pre-wrap;
        margin: 0 auto;
        margin-top: 15.5px;
      }
    }
  }
}
</style>
